import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import EditIcon from "&assets/icons/edit";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import FabButton from "&styled/FabButton";
import {
  successAlert,
  errorAlert,
} from "&config/swalGenerator";

import { RootState } from "&store/store";

import { servicesActions } from "./services.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const ServicesComponent = (props: ReduxProps) => {
  const history = useHistory();
  const {
    state,
    getServices,
    toggleServiceStatus,
  } = props;
  const { data } = state;
  const [pageSize, setPageSize] = React.useState(25);
  const columns: GridColDef[] = [
    {
      field: "merchant",
      headerName: "Merchant",
      renderCell: ({ row }) => row?.merchant?.name ?? "NA",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "serviceName",
      headerName: "Service",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "serviceId",
      headerName: "Service ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "serviceProvider",
      headerName: "Service Provider",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => renderStatus(row),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  useEffect(() => {
    (async () => {
      await getServices();
    })();
  }, [getServices]);

  const changeServiceStatus = async (service) => {
    let servicesClone = [...data];
    let index = servicesClone.map((s) => s._id).indexOf(service._id);
    if (index !== -1) {
      try {
        let params = {
          _id: service._id,
          status: !servicesClone[index].status,
        };

        await toggleServiceStatus(params);

        successAlert("Success", "Service status changed succesfully");
      } catch (ex) {
        console.log(ex);
        errorAlert();
      }
    }
  };

  const renderButtons = (row) => {
    return <Box sx={{ display: "flex", gap: 2 }}>{renderBtnEdit(row)}</Box>;
  };

  const renderBtnEdit = (row) => {
    return (
      <FabButton
        label="Edit"
        onClick={(e: React.MouseEvent) =>
          history.push(`/services/form/${row._id}`)
        }
      >
        <EditIcon />
      </FabButton>
    );
  };

  const renderStatus = (row) => {
    return (
      <Box
        sx={{
          backgroundColor: row.status ? "#D6FFD9" : "#FFE4E4",
          color: row.status ? "#0FA01A" : "#FF1D1D",
          width: "85px",
          textAlign: "center",
          borderRadius: "5px",
          padding: "5px",
          fontSize: "12px",
          lineHeight: "18px",
          cursor: "pointer",
        }}
        onClick={() => changeServiceStatus(row)}
      >
        {row.status ? "Active" : "Suspended"}
      </Box>
    );
  };

  return (
    <>
      <PageHeader
        title="Service Management"
        onAdd={() => history.push("/services/form/new")}
        addText="Create Service"
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100, 500]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.services,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getServices: servicesActions.getServices,
  editServices: servicesActions.editService,
  toggleServiceStatus: servicesActions.toggleServiceStatus,
  setData: servicesActions.setData,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const ServicesComponentRedux = connector(ServicesComponent);

export { ServicesComponentRedux as ServicesComponent };
